import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

const animationCounterFunc = animationDelay => {
  const isBrowser = typeof window !== `undefined`
  if (isBrowser) {
    if (window.matchMedia("(min-width: 769px)").matches) {
      animationDelay += 200
    } else {
      animationDelay = 200
    }
  } else {
    console.log("no browser")
  }
  return animationDelay
}

const Vorstellung = () => {
  let animationDelay = 0
  const data = useStaticQuery(graphql`
    {
      image1: file(relativePath: { eq: "VorstandWPF2020groß.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image2: file(relativePath: { eq: "wpf_vision_header.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image3: file(relativePath: { eq: "hero.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const myRefWpf = React.useRef()
  const myRefVision = React.useRef()
  const myRefBetriebe = React.useRef()

  const handleClick = myRef => {
    myRef.current.click()
  }
  return (
    <section className="section vorstellung">
      <div className="container">
        <article className="message is-large is-primary minHeight">
          <div className="message-header is-size-3 is-centered has-text-centered">
            <p> Die WirtschaftsPlattForm stellt sich vor</p>
          </div>
          <div className="message-body is-size-5 has-text-dark">
            Die WirtschaftsPlattForm Schwechat wurde mit dem Ziel gegründet, den
            Standort Schwechat als Einkaufsstadt für den Konsumenten attraktiver
            zu gestalten und dadurch die in Schwechat wirksame Kaufkraft zu
            erhöhen. Die WirtschaftsPlattForm Schwechat besteht als Verein und
            arbeitet nicht gewinnorientiert.
          </div>
        </article>

        <div className="columns">
          {/* TODO Change image of Vorstand, and change names. Also adjust the vorstand.js page */}
          {/* <div className="column is-4">
            <div
              onClick={handleClick.bind(null, myRefWpf)}
              aria-hidden="true"
              data-sal="fade"
              data-sal-delay={animationCounterFunc(animationDelay)}
              data-sal-duration="1500"
              data-sal-easing="ease"
            >
              <div className="card card-max-height zoom">
                <div className="card-image">
                  <Image
                    className="cardImage"
                    fluid={data.image1.childImageSharp.fluid}
                  ></Image>
                </div>
                <div className="card-content">
                  <div className="media">
                    <div className="media-content">
                      <p className="title is-4">Die WirtschaftsPlattForm</p>
                    </div>
                  </div>
                  <div className="content">
                    <p className="is-size-6">wer sind wir?</p>
                  </div>
                </div>
                <footer className="card-footer">
                  <p className="card-footer-item">
                    <span>
                      <Link
                        ref={myRefWpf}
                        to="/vorstand"
                        aria-label={`Erfahre mehr über die Wirtschaftsplattform`}
                        className="button is-link is-outlined"
                      >
                        Mehr erfahren
                      </Link>
                    </span>
                  </p>
                </footer>
              </div>
            </div>
          </div> */}
          <div className="column is-4">
            <div
              onClick={handleClick.bind(null, myRefVision)}
              aria-hidden="true"
              data-sal="fade"
              data-sal-delay={animationCounterFunc(animationDelay + 200)}
              data-sal-duration="1500"
              data-sal-easing="ease"
            >
              <div className="card card-max-height zoom">
                <div className="card-image">
                  <Image
                    className="cardImage"
                    fluid={data.image2.childImageSharp.fluid}
                  ></Image>
                </div>
                <div className="card-content">
                  <div className="media">
                    <div className="media-content">
                      <p className="title is-4">Vision</p>
                    </div>
                  </div>
                  <div className="content">
                    <p className="is-size-6">Unser Ziel?</p>
                  </div>
                </div>
                <footer className="card-footer">
                  <p className="card-footer-item">
                    <span>
                      <Link
                        ref={myRefVision}
                        to="/vision"
                        aria-label={`Erfahre mehr über unsere Vision`}
                        className="button is-link is-outlined"
                      >
                        Mehr erfahren
                      </Link>
                    </span>
                  </p>
                </footer>
              </div>
            </div>
          </div>
          <div className="column is-4">
            <div
              onClick={handleClick.bind(null, myRefBetriebe)}
              aria-hidden="true"
              data-sal="fade"
              data-sal-delay={animationCounterFunc(animationDelay + 400)}
              data-sal-duration="1500"
              data-sal-easing="ease"
            >
              <div className="card card-max-height zoom">
                <div className="card-image">
                  <Image
                    className="cardImage"
                    fluid={data.image3.childImageSharp.fluid}
                  ></Image>
                </div>
                <div className="card-content">
                  <div className="media">
                    <div className="media-content">
                      <p className="title is-4">Betriebe</p>
                    </div>
                  </div>
                  <div className="content">
                    <p className="is-size-6">Unsere Teilnehmenden Betriebe</p>
                  </div>
                </div>
                <footer className="card-footer">
                  <p className="card-footer-item">
                    <span>
                      <Link
                        ref={myRefBetriebe}
                        to="/betriebe"
                        aria-label={`Sieh dir unsere Betriebe an`}
                        className="button is-link is-outlined"
                      >
                        Zu den Betrieben
                      </Link>
                    </span>
                  </p>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Vorstellung
