import React from "react"
import Layout from "../components/Layout"
import { useStaticQuery, graphql } from "gatsby"
import FeaturedEvents from "../components/Events/FeaturedEvents"
import Vorstellung from "../components/Vorstellung"

const Index = props => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "hero.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout
      title="Schwechat"
      secondTitle="Die Stadt zum Einkaufen und Wohlfühlen"
      fluid={data.file.childImageSharp.fluid}
      alt="Hauptplatz Schwechat"
      seoTitle="WirtschaftsPlattForm Schwechat"
      seoDescription="Die WirtschaftsPlattForm Schwechat wurde mit dem Ziel gegründet, den Standort Schwechat als Einkaufsstadt für den Konsumenten attraktiver zu gestalten und dadurch die in Schwechat wirksame Kaufkraft zu erhöhen. Die WirtschaftsPlattform Schwechat besteht als Verein und arbeitet nicht gewinnorientiert."
    >
      <Vorstellung />
      <FeaturedEvents />
    </Layout>
  )
}
export default Index
