import React, { createRef } from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

const getEvents = graphql`
  query FeaturedEvents {
    allContentfulVeranstaltung(
      limit: 3
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          titelbild {
            fluid(maxWidth: 1280, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          titel
          url
          beschreibung
          id
          author
          createdAt(formatString: "MMM DD, YYYY", locale: "de")
        }
      }
    }
  }
`

const FeaturedEvents = () => {
  const data = useStaticQuery(getEvents)
  let animationCounter = 0
  const isBrowser = typeof window !== `undefined`

  const handleClick = myRef => {
    myRef.current.click()
  }

  return (
    <section className="section featuredEvents">
      <div className="container">
        <article className="message is-large is-primary">
          <div className="message-header is-size-3 is-centered has-text-centered">
            <p>Aktuelle Veranstaltungen</p>
          </div>
        </article>
        <div className="container">
          <div className="columns">
            {data.allContentfulVeranstaltung.edges.map(({ node }) => {
              const myRef = createRef()
              if (isBrowser) {
                if (window.matchMedia("(min-width: 769px)").matches) {
                  animationCounter += 200
                } else {
                  animationCounter = 200
                }
              } else {
                console.log("no browser")
              }
              return (
                <div className="column is-4" key={node.id}>
                  <div
                    onClick={handleClick.bind(null, myRef)}
                    aria-hidden="true"
                    data-sal="fade"
                    data-sal-delay={animationCounter}
                    data-sal-duration="1500"
                    data-sal-easing="ease"
                  >
                    <div className="card zoom">
                      <div className="card-image">
                        <Image
                          className="cardImage"
                          fluid={node.titelbild.fluid}
                        ></Image>
                        <time className="eventCard-date has-text-white">
                          {node.createdAt}
                        </time>
                      </div>
                      <div className="card-content">
                        <div className="media">
                          <div className="media-content">
                            <p className="title is-4">{node.titel}</p>
                            <p className="subtitle is-6">{node.author}</p>
                          </div>
                        </div>
                        <div className="content">
                          <p className="is-size-6">{node.beschreibung}</p>
                        </div>
                      </div>
                      <footer className="card-footer">
                        <p className="card-footer-item">
                          <span>
                            <Link
                              ref={myRef}
                              to={`/veranstaltungen/${node.url}`}
                              className="button is-link is-outlined"
                            >
                              Mehr erfahren
                            </Link>
                          </span>
                        </p>
                      </footer>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="has-text-centered mt-6">
            <Link to="/veranstaltungen" className="button is-link is-medium">
              Zu den Veranstaltungen
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedEvents
